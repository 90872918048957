import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"

import useToggle from "../components/useToggle"
import Footer from "../components/footer"

export default function UberGml() {
  const [dateOpen, toggleDate] = useToggle();
  const [ticketOpen, toggleTicket] = useToggle();
  
  
  return (
    <Layout title="Über GML" description="Die Gesellschaft für Musik und Literatur Kreuzlingen (GML) existiert schon seit 1917. Sie bereichert und fördert das kulturelle Leben der Region mit der Organisation von Konzerten und Lesungen.">
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;
        
        padding: ${spacing.xs};
        margin: 0;

        @media (min-width: ${screen.xsPhone}) {
          padding: ${spacing.medium};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          padding: calc(0.75*${spacing.big}) ${spacing.big};
          margin: 0 ${spacing.medium};
        }
      `}>
        <h1>Über die GML Kreuzlingen</h1>
        <p>
        Die Gesellschaft für Musik und Literatur Kreuzlingen (GML) existiert schon seit 1917. Sie bereichert und fördert das kulturelle Leben der Region mit der Organisation von Konzerten und Lesungen. Nebst fünf Abonnementskonzerten werden weitere Extraveranstaltungen wie Neujahrskonzert, Sommerserenade und weitere Lesungen geplant und durchgeführt. Das Kulturschaffen von Künstlern und Vereinigungen der Region wird dabei besonders berücksichtigt.
        <br/>
        Namhaft unterstützt werden die Bestrebungen der GML von der Stadt Kreuzlingen, sowie von privaten Gönnern und Stiftungen. Die Gesellschaft besteht zur Zeit aus etwa 200 Mitgliedern. Mit der Mitgliedschaft werden die Anstrengungen des Vorstands für hochwertige Programme gewürdigt. Mitglieder werden laufend über die Veranstaltungen orientiert.
        <br/>
        Im Vorstand arbeiten zur Zeit mit: Timon Altwegg, Berg (Präsident), Manuela Eichenlaub, Kreuzlingen (Aktuarin), Benjamin Engeli, Unterentfelden, Susanne Gisin, Bottighofen, Anja Neuweiler, Tägerwilen (Kassierin) und André Simanowski (Überlingen)
        <br/><br/>

        <a href="/statuten-gml.pdf" css={css`text-decoration: none; color: ${color.secondaryDark};`}>Statuten GML</a><br/>
        <a href="/100-jahre-gml-kreuzlingen.pdf" css={css`text-decoration: none; color: ${color.secondaryDark};`}>100 Jahre GML Kreuzlingen</a>
        </p>


        <Footer marginTop={true} />
      </main>
    </Layout>
  )
}
